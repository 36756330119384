export const host = window.location.protocol + '//' + window.location.host + '/api';

export const routingApi = {
    api_default: '/',

    // Authentication
    api_login: '/login',
    api_logout: '/logout',
    api_forgot_password: '/forgot-password',
    api_reset_password: '/reset-password/{token}',
    api_reset_password_validate: '/reset-password/{token}/validate',
    api_create_account: '/create-account',
    api_create_account_validate: '/create-account/{token}/validate',
    api_place_order: '/place-order',
    api_place_order_pay: '/place-order/pay',
    api_place_order_remove: '/place-order/remove',
    api_client_orders: '/{user}/client-orders',
};

export const cacheConfig = {};
