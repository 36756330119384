export const color = {

    primary: '#05b1cc', // OK 1224331A669C
    primaryOpacity: 'rgba(5,177,204,0.2)',
    primaryOpacityMore: 'rgba(5,177,204,0.05)',
    primaryHover: '#06d9fa',

    secondary: '#FF6B6B', // OK
    secondaryOpacity: 'rgba(219,30,232,0.2)',
    secondaryOpacityMore: 'rgba(219,30,232,0.05)',
    secondaryHover: 'rgba(219,30,232,0.80)',

    backgroundPrimary: '#122433',
    backgroundPrimaryOpacity: 'rgba(18,36,51,0.2)',
    backgroundPrimaryMoreOpacity: 'rgba(18,36,51,0.05)',
    backgroundSecondary: '#26293D',

    textPrimary: '#FFFEFE',
    textPrimaryActive: '#ffffff',
    textSecondary: '#9CA1BB',
    textTertiary: '#676a80',
    textError: '#EA5455',
    textDisabled: 'rgba(165,168,182,0.5)',

    tableHead: 'rgba(5,177,204,0.2)',

    colorEdit: '#07D0E7',
    colorEditBackground: 'rgba(7,208,231,0.05)',
    colorEditBackgroundHover: 'rgba(7,208,231,0.15)',
    colorDelete: '#EA5455',
    colorDeleteBackground: 'rgba(234,84,85,0.05)',
    colorDeleteBackgroundHover: 'rgba(234,84,85,0.15)',
    colorCancel: '#EA5455',
    colorCancelBackground: 'rgba(234,84,85,0.05)',
    colorCancelBackgroundHover: 'rgba(234,84,85,0.15)',
    colorDownload: '#6F61F0FF',
    colorDownloadBackground: 'rgba(111,97,240,0.05)',
    colorDownloadBackgroundHover: 'rgba(111,97,240,0.15)',
    colorConfirm: '#6F61F0FF',
    colorConfirmBackground: 'rgba(111,97,240,0.05)',
    colorConfirmBackgroundHover: 'rgba(111,97,240,0.15)',
    colorSecondConfirm: '#DB1EE8',
    colorSecondConfirmBackground: 'rgba(219,30,232,0.05)',
    colorSecondConfirmBackgroundHover: 'rgba(219,30,232,0.15)',
    colorRestore: '#49934B',
    colorRestoreBackground: 'rgba(73,147,75,0.05)',
    colorRestoreBackgroundHover: 'rgba(73,147,75,0.15)',

    colorSuccess: '#49934B',
    colorSuccessBackground: 'rgba(73,147,75,0.15)',
    colorInfo: '#17a2b8',
    colorInfoBackground: 'rgba(23,162,184,0.15)',
    colorWarning: '#FFC107',
    colorWarningBackground: 'rgba(255,193,7,0.15)',
    colorError: '#EA5455',
    colorErrorBackground: 'rgba(234,84,85,0.15)',
};

