import {routing} from "../Config/Routing";

let routes = {};
let defaultPath;

export const routingHandlerGetRouteInfo = (id) => {
    let route = routing[id];
    return route ? {...route, id: id} : {};
};

export const routingHandlerGetRoutePathname = (id, params = []) => {
    let route = routingHandlerGetRouteInfo(id);
    let path = route.path ?? '/';

    for (let index in params) {
        let regex = new RegExp('/:' + index + '[?]{0,1}', 'gi')
        path = path.replace(regex, params[index] ? '/' + params[index] : '');
    }

    return path;
};

export const routingHandlerSecurity = (authenticationRedux) => {
    routes = {};
    defaultPath = '';

    if (authenticationRedux.roles) { // Login
        for (let index in routing) {
            if (authenticationRedux.roles.filter(value => routing[index].roles.includes(value)).length > 0) {
                routes[index] = routing[index];
            }
        }

        if (authenticationRedux.defaultRoute && routes[authenticationRedux.defaultRoute]) {
            defaultPath = routes[authenticationRedux.defaultRoute].path;
        }
        else {
            if (Object.keys(routes)[0]) {
                defaultPath = routes[Object.keys(routes)[0]].path
            }
            else { // Logout
                routes.login = routing['login'];
                routes.forgotPassword = routing['forgotPassword'];
                routes.createAccount = routing['createAccount'];
                routes.resetPassword = routing['resetPassword'];
                defaultPath = routing['login'].path;
            }
        }
    }
    else { // Logout
        routes.login = routing['login'];
        routes.forgotPassword = routing['forgotPassword'];
        routes.createAccount = routing['createAccount'];
        routes.resetPassword = routing['resetPassword'];
        defaultPath = routing['login'].path;
    }

    return {defaultPath: defaultPath, routes: routes};
};

export const routingHandlerGetRouteByPathname = (pathname) => {
    for (let index in routes) {
        if (routes[index].match && routes[index].match.test(pathname)) {
            return {...routes[index], id: index};
        }
        else if (routes[index].path === pathname) {
            return {...routes[index], id: index};
        }
    }

    return {};
};

export const routingHandlerSecurityGetRoutes = () => {
    return routes;
};
