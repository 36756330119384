// Layouts
import AuthenticationLayout from "src/Layout/AuthenticationLayout/AuthenticationLayout";
import MainLayout from "src/Layout/MainLayout/MainLayout";

// Views
import Login from "src/View/Login/Login";
import ForgotPassword from "src/View/ForgotPassword/ForgotPassword";
import ResetPassword from "src/View/ResetPassword/ResetPassword";
import CreateAccount from "src/View/CreateAccount/CreateAccount";
import Dashboard from "src/View/Dashboard/Dashboard";

export const routing = {
    // Authentication
    login: {path: '/login', view: Login, layout: AuthenticationLayout, roles: []},
    forgotPassword: {path: '/forgot-password', view: ForgotPassword, layout: AuthenticationLayout, roles: []},
    resetPassword: {path: '/reset-password/:token?', view: ResetPassword, layout: AuthenticationLayout, roles: []},
    createAccount: {path: '/create-account', view: CreateAccount, layout: AuthenticationLayout, roles: []},

    // Dashboard
    dashboard: {path: '/dashboard', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},
};
