import React, {useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Box} from "@mui/system";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {Alert, Fade} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "src/Config/Theme";
import {useDispatch} from "react-redux";
import {authenticationReduxLogin} from "src/Redux/authenticationRedux";

// ** Styled Components
const LinkStyled = styled(Link)(() => ({
    fontSize: '13px',
    textDecoration: 'none',
    color: color.primary
}));

function Login() {
    const dispatch = useDispatch();
    const [isError, setIsError] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const location = useLocation();
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const token = queryParams.get('token');

    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'E-mail',
            helperText: 'Enter your e-mail.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        },
        password: {
            id: 'password',
            label: 'Password',
            helperText: 'Enter your password.',
            type: 'password',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const loginAPI = () => {
        document.querySelectorAll('input').forEach(input => input.blur());

        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setIsError(false);
            setLoading(true);
            setIsSuccess(false);

            routingApiHandlerFetch({
                route: 'api_login',
                method: 'POST',
                data: {
                    email: form.email.value,
                    password: form.password.value,
                },
                success: (response) => {
                    dispatch(authenticationReduxLogin(response.data));
                    handler.setLoading(false);
                    setLoading(false);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        setIsError(true);
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        if (!token) return;
        handler.setLoading(true);
        setLoading(true);

        routingApiHandlerFetch({
            route: 'api_create_account_validate',
            method: 'GET',
            params: {token: token},
            success: () => {
                handler.setLoading(false);
                setLoading(false);
                setIsSuccess(true);
            },
            error: (response) => {
                handler.setLoading(false);
                setLoading(false);
            }
        });
    }, [token]);

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            overflowX: 'hidden',
            position: 'relative'
        }}>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{
                    width: '100%'
                }}>
                    <Box
                        sx={{
                            padding: '0 25px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{width: '100%', maxWidth: 400}}>

                            <LinkStyled to={'https://www.backlinkshub.io/'}>
                                <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                            </LinkStyled>

                            <Box sx={{margin: '15px 0'}}>
                                <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                    {`Welcome to the BackLinks Hub IO platform! 👋🏻`}
                                </Typography>
                                <Typography sx={{color: color.textPrimary, fontWeight: '100 !important'}}>
                                    Please log in to your account
                                </Typography>
                            </Box>


                            {isSuccess && <>
                                <Alert sx={{opacity: 0.7}} severity="success">
                                    Your account has been successfully validated, let your climb to the top begin!
                                </Alert><br/>
                            </>}
                            {isError && <>
                                <Alert sx={{opacity: 0.7}} severity="error">Oops ! An unexpected error has occurred.</Alert><br/>
                            </>}

                            <div>
                                <Box sx={{padding: '5px 0'}}>
                                    <TextFieldComponent handler={handler} id={'email'} onSubmit={loginAPI}/>
                                </Box>
                                <Box sx={{padding: '5px 0'}}>
                                    <TextFieldComponent handler={handler} id={'password'} onSubmit={loginAPI}/>
                                </Box>

                                <Box sx={{textAlign: 'end', width: '100%'}}>
                                    <LinkStyled to={routingHandlerGetRoutePathname('forgotPassword')}>Forgot your password ?</LinkStyled>
                                </Box>

                                <Box sx={{width: '100%', marginTop: '25px'}}>
                                    <ButtonComponent label={'Login'} onClick={loginAPI} loading={loading}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%', marginTop: '20px'}}>
                                    <LinkStyled sx={{fontSize: '16px'}} to={routingHandlerGetRoutePathname('createAccount')}>Create an account</LinkStyled> <br/>
                                    <Typography sx={{color: color.textPrimary, fontSize: '11px'}}>
                                        it takes less than 20 seconds
                                    </Typography>
                                </Box>
                            </div>

                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Box>
    )
}

export default Login;
