import React from 'react';
import {Link} from "react-router-dom";
import {Box} from "@mui/system";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {Alert, Fade} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "../../Config/Theme";
import {ArrowBackIos} from '@mui/icons-material';

// ** Styled Components
const LinkStyled = styled(Link)(() => ({
    fontSize: '15px',
    textDecoration: 'none',
    color: '#05b1cc'
}));

function CreateAccount() {
    const [isError, setIsError] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [acceptedConditions, setAcceptedConditions] = React.useState(false);
    const [acceptedConditionsForce, setAcceptedConditionsForce] = React.useState(false);

    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'E-mail',
            helperText: 'Enter your e-mail.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        },
        password: {
            id: 'password',
            label: 'Password',
            helperText: 'Enter your password.',
            type: 'password',
            value: '',
            options: {validation: ['required'], min: 6, max: 52}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const createAccount = () => {
        document.querySelectorAll('input').forEach(input => input.blur());
        if (handler.checkValid() < 1) {
            if (!acceptedConditions) {
                setAcceptedConditionsForce(true);
                return;
            }

            handler.setLoading(true);
            setIsError(false);
            setIsSuccess(false);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_create_account',
                method: 'POST',
                data: {
                    email: form.email.value,
                    password: form.password.value
                },
                success: () => {
                    handler.reset();
                    setIsSuccess(true);
                    handler.setLoading(false);
                    setLoading(false);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        setIsError(true);
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }

    };

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            overflowX: 'hidden',
            position: 'relative'
        }}>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{
                    width: '100%'
                }}>
                    <Box
                        sx={{
                            padding: '0 25px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{width: '100%', maxWidth: 400}}>

                            <LinkStyled to={'https://www.backlinkshub.io/'}>
                                <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                            </LinkStyled>

                            <Box sx={{margin: '15px 0'}}>
                                <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                    {`Adventure starts here 🚀`}
                                </Typography>
                                <Typography sx={{color: color.textPrimary, fontWeight: '100 !important'}}>
                                    It all starts here, let's crush the competition together
                                </Typography>
                            </Box>

                            {isError && <><Alert sx={{opacity: 0.7}} severity="error">Oops ! An unexpected error has occurred.</Alert><br/></>}
                            {isSuccess && <><Alert sx={{opacity: 0.7}} severity="success">Your account has been successfully created. Please check your email to confirm your account. If you do not see the email in your inbox, please check your spam or junk folder.
                            </Alert><br/></>}

                            <div>
                                <Box sx={{padding: '5px 0'}}>
                                    <TextFieldComponent handler={handler} id={'email'} onSubmit={createAccount}/>
                                </Box>
                                <Box sx={{padding: '5px 0'}}>
                                    <TextFieldComponent handler={handler} id={'password'} onSubmit={createAccount}/>
                                </Box>

                                <div className="uv-checkbox-wrapper">
                                    <input type="checkbox" id="uv-checkbox" className="uv-checkbox" defaultChecked={acceptedConditions} checked={acceptedConditions}/>
                                    <label htmlFor="uv-checkbox" className="uv-checkbox-label" onClick={() => {
                                        setAcceptedConditions(!acceptedConditions);
                                        setAcceptedConditionsForce(false);
                                    }}>
                                        <div className="uv-checkbox-icon" style={{width: '37px'}}>
                                            <svg viewBox="0 0 24 24" className="uv-checkmark">
                                                <path d="M4.1,12.7 9,17.6 20.3,6.3" fill="none"></path>
                                            </svg>
                                        </div>
                                        <span className="uv-checkbox-text" style={{color: acceptedConditionsForce ? color.colorError : color.textSecondary}}>
                                            By creating an account, you agree to our
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/legal-notice/"> Legal Notice</a>,
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/gtc/"> General Terms and Conditions of Sale</a>,
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/gtcu/"> General Terms and Conditions of Use</a>, and
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/privacy-policy/"> Privacy Policy</a>.
                                        </span>
                                    </label>
                                </div>
                                <br/>
                                <br/>

                                <Box sx={{textAlign: 'center', width: '100%', marginBottom: '25px'}}>
                                    <ButtonComponent label={'Create an account'} onClick={createAccount} loading={loading}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%'}}>
                                    <LinkStyled to={routingHandlerGetRoutePathname('login')}>
                                        <ArrowBackIos sx={{fontSize: 16, color: color.primary, verticalAlign: 'bottom'}}/> Return to login
                                    </LinkStyled>
                                </Box>
                            </div>

                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Box>
    );
}

export default CreateAccount;
