import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import momentFR from "moment-locale-fr";
import moment from 'moment';
import {authenticationReduxLogout} from "src/Redux/authenticationRedux";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import Loading from "./View/Loading/Loading";
import {routingHandlerSecurity} from "./Handler/RoutingHandler";
import ScreenHigher from "src/Higher/ScreenHigher";
import SnackbarHigher from "./Higher/SnackbarHigher";

// Moment
moment.updateLocale('fr', momentFR);
moment.locale('fr');

// Dispatch
export let dispatch;

function App() {
    const [isReady, setIsReady] = React.useState(false);

    // Redux
    dispatch = useDispatch();
    const authenticationRedux = useSelector((state) => state.authenticationRedux);

    // Security & Routes
    const [routes, setRoutes] = React.useState({});
    const [routeDefault, setRouteDefault] = React.useState('');

    React.useEffect(() => {
        let routesSecurity = routingHandlerSecurity(authenticationRedux);
        setRoutes({...routesSecurity.routes});
        setRouteDefault(routesSecurity.defaultPath);
    }, [authenticationRedux]);
    React.useEffect(() => {
        routingApiHandlerFetch({
            route: 'api_default',
            method: 'GET',
            success: (response) => {
                if (!response.data.authenticated && !!authenticationRedux.email) {
                    dispatch(authenticationReduxLogout());
                }

                setTimeout(() => setIsReady(true), 2000);
            },
            error: (response) => {
                setTimeout(() => setIsReady(true), 2000);
            }
        });
    }, []);

    return (
        <>
            {
                isReady ? <BrowserRouter>
                    <Routes>
                        {
                            Object.keys(routes).map((key) => {
                                let route = routes[key];
                                return <Route key={'route_' + key} path={route.path} element={<route.layout view={route.view}/>}/>;
                            })
                        }
                        <Route path="*" element={<Navigate to={routeDefault} replace/>}/>
                    </Routes>
                </BrowserRouter> : <Loading/>
            }

            <ScreenHigher/>
            <SnackbarHigher/>
        </>
    )
}

export default App;
