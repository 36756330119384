import React from 'react'
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {Box} from "@mui/system";
import {color} from "src/Config/Theme";

function SelectComponent(props) {
    const [isFocus, setIsFocus] = React.useState(false);
    let input = props.handler.get(props.id);

    const onChange = (event) => {
        props.handler.setError(props.id, '');
        props.handler.setValue(props.id, event.target.value);
        if (props.onChange) props.onChange(event.target.value);
    };
    const onBlur = () => {
        setIsFocus(false);
        props.handler.checkValidBy(props.id);
    };
    const onFocus = () => {
        setIsFocus(true);
    };

    const style = {
        multipleSelect: {
            width: '100%',
            position: 'relative',
            '& .MuiSelect-icon': {
                color: color.textPrimary,
            },
            '& input, & .MuiSelect-select': {
                cursor: input.loading ? 'wait' : input.disabled ? 'not-allowed' : 'pointer',
                padding: '10px 14px',
                fontSize: 14,
                color: color.textPrimary,
                WebkitTextFillColor: color.textPrimary + ' !important;',
                caretColor: color.textPrimary
            },
            '& .MuiOutlinedInput-root': {
                fontSize: 14
            },
            '& .MuiInputLabel-outlined': {
                transform: 'translate(13px, 9px) scale(1)',
                fontSize: 14
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(15px, -7px) scale(0.75)',
            },
            '& .MuiFormHelperText-root': {
                margin: '5px 14px',
                fontSize: 10,
                minHeight: 10,
                lineHeight: '10px',
                color: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textTertiary + ' !important'
            },
            '&:hover .MuiFormHelperText-root': {
                color: (input.loading || input.disabled) ? color.textTertiary + ' !important' : isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important'
            },
            '& fieldset': {
                borderWidth: '1px !important',
                borderColor: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textTertiary + ' !important',
                // boxShadow: isFocus ? ('0px 0px 2px 2px ' + color.primary) : 'none'
            },
            '&:hover fieldset': {
                borderColor: (input.loading || input.disabled) ? color.textTertiary + ' !important' : isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : color.textPrimary + ' !important',
            },
            '& label': {
                color: isFocus ? color.primary + ' !important' : input.error ? color.textError + ' !important' : ((input.loading || input.disabled) ? color.textTertiary : color.textPrimary + ' !important')
            }
        }
    };

    return (
        <Box sx={{width: '100%', position: 'relative'}}>
            <FormControl variant="outlined" sx={style.multipleSelect}>
                <InputLabel htmlFor="outlined">{input.label}</InputLabel>
                <Select
                    labelId={input.name}
                    multiple={input.type === 'array'}
                    value={input.value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoFocus={false}
                    inputProps={{name: input.label}}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        sx: {
                            '& ul': {
                                background: color.backgroundPrimary,
                                color: color.textPrimary,
                                '& li': {
                                    zIndex: 3,
                                    padding: '4px 10px',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    minHeight: '16px',
                                    lineHeight: '16px',
                                    color: color.textPrimary,
                                    '&:hover': {
                                        background: color.primaryOpacityMore + ' !important',
                                        color: color.primary,
                                    }
                                }
                            }
                        },
                    }}
                    label={input.label}
                    disabled={input.disabled}
                >
                    {
                        props.options.map(data => (
                            <MenuItem key={data.value} value={data.value}>
                                {data.label}
                            </MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText>{isFocus ? input.helperText : input.error ? input.error : (input.helperText + ((input.options && input.options.validation && input.options.validation.indexOf('required') > -1) ? '' : ' (Optionnel)'))}</FormHelperText>
            </FormControl>
        </Box>
    )
}

export default SelectComponent;
