import React from 'react';
import {Box} from "@mui/system";
import {useDispatch, useSelector} from "react-redux";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip} from "@mui/material";
import {color} from "src/Config/Theme";
import Typography from "@mui/material/Typography";
import {PowerSettingsNew, Edit, Receipt, DeleteOutline, CreditCard} from "@mui/icons-material";
import TableComponent from "src/Component/TableComponent";
import {authenticationReduxLogout} from "src/Redux/authenticationRedux";
import ButtonComponent from "src/Component/ButtonComponent";
import {formHandlerInit} from "src/Handler/FormHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import SelectComponent from "src/Component/SelectComponent";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import {tableReduxReload} from "src/Redux/tableRedux";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {useLocation} from "react-router-dom";

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex', verticalAlign: 'bottom'}}>
            <CircularProgress size={30} variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color={color.textSecondary} sx={{fontSize: '10px'}}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

function getLabelListByValue(elements, value) {
    const element = elements.find(service => service.value === value);
    return element ? element.labelList : null;
}

const customSort = (order) => {
    return (obj1, obj2) => {
        const val1 = obj1.data.props.sort;
        const val2 = obj2.data.props.sort;

        if (val1 < val2) {
            return order === 'asc' ? -1 : 1;
        }
        if (val1 > val2) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    };
}

function Dashboard() {
    const location = useLocation();

    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [acceptedConditions, setAcceptedConditions] = React.useState(false);
    const [acceptedConditionsForce, setAcceptedConditionsForce] = React.useState(false);
    const [uuidClientOrder, setUuidClientOrder] = React.useState(null);

    // Query
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    const queryParams = getQueryParams(location.search);
    const sessionId = queryParams.get('session_id');

    // Redux
    const dispatch = useDispatch();
    const authenticationRedux = useSelector((state) => state.authenticationRedux);

    // Tables
    const columns = [
        {
            name: "uuidList",
            label: "Reference",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date",
            options: {filter: true, sort: true}
        },
        {
            name: "name",
            label: "Name",
            options: {filter: true, sort: true}
        },
        {
            name: "urlList",
            label: "URL",
            options: {filter: true, sort: true, sortCompare: customSort}
        },
        {
            name: "keywords",
            label: "Keywords",
            options: {filter: true, sort: true}
        },
        {
            name: "serviceList",
            label: "Service",
            options: {filter: true, sort: true}
        },
        {
            name: "progressionList",
            label: "Progression",
            options: {filter: true, sort: true}
        },
        {
            name: "statusList",
            label: "Status",
            options: {filter: true, sort: true}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];

    // Dialog
    const languages = [
        {value: 'fr', label: 'FR - Français'},
        {value: 'en', label: 'EN - English'},
        {value: 'es', label: 'ES - Español'},
        {value: 'it', label: 'IT - Italiano'},
        {value: 'de', label: 'DE - Deutsch'},
        {value: 'pt', label: 'PT - Português'},
        {value: 'nl', label: 'NL - Nederlands'},
        {value: 'ru', label: 'RU - Русский'},
        {value: 'zh', label: 'ZH - 中文'},
        {value: 'ja', label: 'JA - 日本語'},
        {value: 'ko', label: 'KO - 한국어'},
        {value: 'ar', label: 'العربية - AR'},
        {value: 'hi', label: 'HI - हिन्दी'},
        {value: 'tr', label: 'TR - Türkçe'},
        {value: 'sv', label: 'SV - Svenska'},
        {value: 'da', label: 'DA - Dansk'},
        {value: 'no', label: 'NO - Norsk'},
        {value: 'fi', label: 'FI - Suomi'},
        {value: 'pl', label: 'PL - Polski'},
        {value: 'el', label: 'EL - Ελληνικά'}
    ];
    const themes = [
        {value: 'WHATEVER', label: 'Whatever...'},
        {value: 'ADULT', label: 'Adult'},
        {value: 'ART', label: 'Art'},
        {value: 'AUTOMOTIVE', label: 'Automotive'},
        {value: 'BUSINESS', label: 'Business'},
        {value: 'COOKING', label: 'Cooking'},
        {value: 'EDUCATION', label: 'Education'},
        {value: 'ENTERTAINMENT', label: 'Entertainment'},
        {value: 'FASHION', label: 'Fashion'},
        {value: 'FINANCE', label: 'Finance'},
        {value: 'GAMING', label: 'Gaming'},
        {value: 'GARDENING', label: 'Gardening'},
        {value: 'HEALTH', label: 'Health'},
        {value: 'HOME', label: 'Home'},
        {value: 'LUXURY', label: 'Luxury'},
        {value: 'MUSIC', label: 'Music'},
        {value: 'NEWS', label: 'News'},
        {value: 'PHOTOGRAPHY', label: 'Photography'},
        {value: 'SOCIAL-MEDIA', label: 'Social Media'},
        {value: 'SPORTS', label: 'Sports'},
        {value: 'TECHNOLOGY', label: 'Technology'},
        {value: 'TRAINING', label: 'Training'},
        {value: 'TRAVEL', label: 'Travel'},
        {value: 'WELLNESS', label: 'Wellness'}
    ];
    const services = [
        {value: '', label: 'Essential'},
        {value: 'ESSENTIAL_STANDARD', labelList: 'Essential - Standard 20 Backlinks', label: 'Essential - Standard 20 Backlinks (39,99€ VAT included)'},
        {value: 'ESSENTIAL_ADVANCE', labelList: 'Essential - Advance 40+2 Backlinks', label: 'Essential - Advance 40+2 Backlinks (79,99€ VAT included)'},
        {value: 'ESSENTIAL_ELITE', labelList: 'Essential - Elite 80+8 Backlinks', label: 'Essential - Elite 80+8 Backlinks (159,99€ VAT included)'},
        {value: 'ESSENTIAL_PRO', labelList: 'Essential - Pro 160+25 Backlinks', label: 'Essential - Pro 160+25 Backlinks (319,99€ VAT included)'},
        {value: '', label: 'Champion'},
        {value: 'CHAMPION_STANDARD', labelList: 'Champion - Standard+ 10 Backlinks', label: 'Champion - Standard+ 10 Backlinks (39,99€ VAT included)'},
        {value: 'CHAMPION_ADVANCE', labelList: 'Champion - Advance+ 20+1 Backlinks', label: 'Champion - Advance+ 20+1 Backlinks (79,99€ VAT included)'},
        {value: 'CHAMPION_ELITE', labelList: 'Champion - Elite+ 40+4 Backlinks', label: 'Champion - Elite+ 40+4 Backlinks (159,99€ VAT included)'},
        {value: 'CHAMPION_PRO', labelList: 'Champion - Pro+ 80+12 Backlinks', label: 'Champion - Pro+ 80+12 Backlinks (319,99€ VAT included)'},
        {value: '', label: 'Package Ultimate'},
        {value: 'PACKAGE_ULTIMATE_STARTER', labelList: 'Package Ultimate - Starter Pack 98 Backlinks', label: 'Package Ultimate - Starter Pack 98 Backlinks (179,99€ VAT included)'},
        {value: 'PACKAGE_ULTIMATE_INFINITY', labelList: 'Package Ultimate - Infinity 229 Backlinks', label: 'Package Ultimate - Infinity 229 Backlinks (429,99€ VAT included)'},
    ];
    const status = [
        {value: 'STATUS_DRAFT', labelList: 'Draft'},
        {value: 'STATUS_WAITING_PAYMENT', labelList: 'Waiting for payment'},
        {value: 'STATUS_CANCELED', labelList: 'Canceled'},
        {value: 'STATUS_IN_PROGRESS', labelList: 'In progress'},
        {value: 'STATUS_FINISHED', labelList: 'Finished'},
    ];

    const save = (withPayment) => {
        if (handler.checkValid() < 1) {
            if (!acceptedConditions) {
                setAcceptedConditionsForce(true);
                return;
            }

            handler.setLoading(true);
            setLoading(true);

            // Save
            routingApiHandlerFetch({
                route: 'api_place_order',
                method: 'POST',
                data: {
                    uuid: uuidClientOrder,
                    withPayment: withPayment,
                    name: form.name.value,
                    url: form.url.value,
                    keywords: form.keywords.value,
                    language: form.language.value,
                    service: form.service.value,
                    theme: form.theme.value,
                },
                success: (response) => {
                    if (response.data.url) {
                        window.location.href = response.data.url;
                    }
                    else {
                        handler.reset();
                        setOpenDialog(false);
                        handler.setLoading(false);
                        setLoading(false);
                        setTimeout(() => dispatch(tableReduxReload({id: 'api_client_orders'})), 50);
                    }
                },
                error: (response) => {
                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };
    const getAction = (row) => {
        return (
            <Box>
                {
                    (row.status === 'STATUS_DRAFT') &&
                    <>
                        <ButtonIconComponent
                            tooltip={'Remove'}
                            background={color.colorDeleteBackground}
                            backgroundHover={color.colorDeleteBackgroundHover}
                            color={color.colorDelete}
                            icon={DeleteOutline}
                            onClick={() => {
                                routingApiHandlerFetch({
                                    route: 'api_place_order_remove',
                                    method: 'POST',
                                    data: {
                                        uuid: row.uuid,
                                    },
                                    success: (response) => {
                                        setTimeout(() => dispatch(tableReduxReload({id: 'api_client_orders'})), 50);
                                        dispatch(snackbarReduxOpen({text: 'Order successfully canceled.', variant: 'success'}));
                                    },
                                    error: (response) => {
                                    }
                                });
                            }}
                        />
                        <ButtonIconComponent
                            tooltip={'Edit'}
                            sx={{marginLeft: '5px'}}
                            background={color.colorEditBackground}
                            backgroundHover={color.colorEditBackgroundHover}
                            color={color.colorEdit}
                            icon={Edit}
                            onClick={() => {
                                handler.setDataApi(row);
                                setUuidClientOrder(row.uuid);
                                setOpenDialog(true);
                            }}
                        />
                    </>
                }
                {
                    (row.status === 'STATUS_WAITING_PAYMENT') &&
                    <>
                        <ButtonIconComponent
                            tooltip={'Remove'}
                            background={color.colorDeleteBackground}
                            backgroundHover={color.colorDeleteBackgroundHover}
                            color={color.colorDelete}
                            icon={DeleteOutline}
                            onClick={() => {
                                routingApiHandlerFetch({
                                    route: 'api_place_order_remove',
                                    method: 'POST',
                                    data: {
                                        uuid: row.uuid,
                                    },
                                    success: (response) => {
                                        setTimeout(() => dispatch(tableReduxReload({id: 'api_client_orders'})), 50);
                                        dispatch(snackbarReduxOpen({text: 'Order successfully canceled.', variant: 'success'}));
                                    },
                                    error: (response) => {
                                    }
                                });
                            }}
                        />
                        <ButtonIconComponent
                            tooltip={'Check my payment'}
                            sx={{marginLeft: '5px'}}
                            background={color.colorRestoreBackground}
                            backgroundHover={color.colorRestoreBackgroundHover}
                            color={color.colorRestore}
                            icon={CreditCard}
                            onClick={() => {
                                routingApiHandlerFetch({
                                    route: 'api_place_order_pay',
                                    method: 'POST',
                                    data: {
                                        uuid: row.uuid,
                                    },
                                    success: (response) => {
                                        if (response.data.url) {
                                            window.location.href = response.data.url;
                                        }
                                        else {
                                            dispatch(snackbarReduxOpen({text: 'Payment already validated.', variant: 'success'}));
                                            setTimeout(() => dispatch(tableReduxReload({id: 'api_client_orders'})), 50);
                                        }
                                    },
                                    error: (response) => {
                                    }
                                });
                            }}
                        />
                    </>
                }
                {
                    (row.status === 'STATUS_IN_PROGRESS' || row.status === 'STATUS_FINISHED') &&
                    <ButtonIconComponent
                        tooltip={'Invoice'}
                        background={color.colorDownloadBackground}
                        backgroundHover={color.colorDownloadBackgroundHover}
                        color={color.colorDownload}
                        icon={Receipt}
                        disabled={true}
                    />
                }
            </Box>
        );
    };

    // Form
    const [form, setForm] = React.useState({
        name: {
            id: 'name',
            label: 'Name',
            helperText: 'Give a name or label to your order.',
            type: 'text',
            value: '',
            options: {}
        },
        url: {
            id: 'url',
            label: 'URL',
            helperText: 'Enter the URL you want to boost.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        keywords: {
            id: 'keywords',
            label: 'Keywords',
            helperText: 'Give us 3 to 5 keywords separated by commas.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        language: {
            id: 'language',
            label: 'Language',
            helperText: 'Choose the language of the articles for your future backlinks.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        service: {
            id: 'service',
            label: 'Service',
            helperText: 'Choose a backlink service.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        theme: {
            id: 'theme',
            label: 'Theme',
            helperText: 'Choose the theme of your domain to obtain targeted sites.',
            type: 'text',
            value: '',
            disabled: true,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);

    React.useEffect(() => {
        if (sessionId) {
            routingApiHandlerFetch({
                route: 'api_place_order_pay',
                method: 'POST',
                data: {
                    sessionId: sessionId,
                },
                success: (response) => {
                    if (response.data.url) {
                        // window.location.href = response.data.url;
                    }
                    else {
                        setTimeout(() => dispatch(tableReduxReload({id: 'api_client_orders'})), 50);
                        dispatch(snackbarReduxOpen({text: 'Payment validated successfully.', variant: 'success'}));
                    }
                },
                error: (response) => {
                }
            });
        }
    }, [sessionId]);

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <ShadowBoxComponent sx={{border: '1px solid ' + color.textTertiary, borderRadius: '5px', padding: '10px 15px'}}>
                        <img src="/images/logo.webp" alt="Logo" style={{width: '25px', float: 'left'}}/>
                        <Typography sx={{color: color.textSecondary, marginLeft: '40px'}}>Backlinks HUB</Typography>
                        <PowerSettingsNew
                            onClick={() => dispatch(authenticationReduxLogout())}
                            sx={{color: color.textSecondary, float: 'right', top: '10px', position: 'absolute', right: '15px', cursor: 'pointer'}}
                        />
                    </ShadowBoxComponent>
                </Grid>
            </Grid>

            <ShadowBoxComponent sx={{border: '1px solid ' + color.textTertiary, borderRadius: '5px', marginTop: '10px', padding: 0}}>
                <TableComponent
                    id={'api_client_orders'}
                    title={'Tracking your orders'}
                    columns={columns}
                    height={'calc(100vh - 205px)'}
                    refreshInterval={10000}
                    promiseData={(resolve) => {
                        routingApiHandlerFetch({
                            route: 'api_client_orders',
                            params: {user: authenticationRedux.uuid},
                            method: 'GET',
                            success: (response) => {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].actions = getAction(data[index]);
                                    data[index].serviceList = getLabelListByValue(services, data[index].service);
                                    data[index].statusList = getLabelListByValue(status, data[index].status);
                                    data[index].uuidList = data[index].uuid.split('-')[0];
                                    data[index].progressionList = <CircularProgressWithLabel value={data[index].progression}/>;
                                    data[index].urlList = <a style={{color: color.textPrimaryActive}} sort={data[index].url} href={data[index].url} target={'_blank'}>{data[index].url}</a>;
                                }
                                resolve(data);
                            },
                            error: () => {
                                resolve([]);
                            }
                        });
                    }}
                    actionFirst={{
                        label: 'Start Order',
                        onClick: () => {
                            handler.reset();
                            setOpenDialog(true);
                        }
                    }}
                />
            </ShadowBoxComponent>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="responsive-dialog-title" sx={{background: color.backgroundPrimary, color: color.textPrimary}}>
                    Place order
                </DialogTitle>
                <DialogContent sx={{background: color.backgroundPrimary, paddingTop: '5px !important', padding: '5px 24px'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextFieldComponent handler={handler} id={'name'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent handler={handler} id={'url'}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <TextFieldComponent handler={handler} id={'keywords'}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <SelectComponent handler={handler} id={'language'} options={languages}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <SelectComponent handler={handler} id={'service'} options={services} onChange={(value) => {
                                if (
                                    value === 'CHAMPION_STANDARD' ||
                                    value === 'CHAMPION_ADVANCE' ||
                                    value === 'CHAMPION_ELITE' ||
                                    value === 'CHAMPION_PRO' ||
                                    value === 'PACKAGE_ULTIMATE_STARTER' ||
                                    value === 'PACKAGE_ULTIMATE_INFINITY') {
                                    form.theme.options = {validation: ['required']};
                                    handler.setDisabled('theme', false);
                                }
                                else {
                                    form.theme.options = {};
                                    handler.setDisabled('theme', true);
                                    handler.setValue('theme', '');
                                    handler.setError('theme', '');
                                }
                            }}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <SelectComponent handler={handler} id={'theme'} options={themes}/>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="uv-checkbox-wrapper">
                                <input type="checkbox" id="uv-checkbox" className="uv-checkbox" defaultChecked={acceptedConditions} checked={acceptedConditions}/>
                                <label htmlFor="uv-checkbox" className="uv-checkbox-label" onClick={() => {
                                    setAcceptedConditions(!acceptedConditions);
                                    setAcceptedConditionsForce(false);
                                }}>
                                    <div className="uv-checkbox-icon" style={{width: '37px'}}>
                                        <svg viewBox="0 0 24 24" className="uv-checkmark">
                                            <path d="M4.1,12.7 9,17.6 20.3,6.3" fill="none"></path>
                                        </svg>
                                    </div>
                                    <span className="uv-checkbox-text" style={{color: acceptedConditionsForce ? color.colorError : color.textSecondary}}>
                                            By placing an order, you agree to our
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/legal-notice/"> Legal Notice</a>,
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/gtc/"> General Terms and Conditions of Sale</a>,
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/gtcu/"> General Terms and Conditions of Use</a>, and
                                            <a style={{color: color.textPrimaryActive}} target={'_blank'} href="https://www.backlinkshub.io/en/privacy-policy/"> Privacy Policy</a>.
                                        The backlink deployment process is designed to take approximately six months.
                                        This extended period allows you to create a natural and organic effect, thus promoting better positioning in search engines.
                                    </span>
                                </label>
                            </div>
                            <br/>
                            <br/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{background: color.backgroundPrimary}}>
                    <ButtonComponent onClick={() => setOpenDialog(false)} label={'Cancel'} color={color.textTertiary} colorHover={color.textSecondary}/>
                    <ButtonComponent onClick={() => save(false)} label={'Save as draft'} loading={loading} color={color.secondaryOpacity} colorHover={color.secondaryHover}/>
                    <ButtonComponent onClick={() => save(true)} label={'Place Order'} loading={loading}/>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Dashboard;
