import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {Box} from "@mui/system";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {Alert, Fade} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "../../Config/Theme";
import {ArrowBackIos} from '@mui/icons-material';

// ** Styled Components
const LinkStyled = styled(Link)(() => ({
    fontSize: '15px',
    textDecoration: 'none',
    color: '#05b1cc'
}));

function ResetPassword() {
    const [isError, setIsError] = React.useState(false);
    const [isExpired, setIsExpired] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const {token} = useParams();

    // Form
    const [form, setForm] = React.useState({
        password: {
            id: 'password',
            label: 'Password',
            helperText: 'Enter your password.',
            type: 'password',
            value: '',
            options: {validation: ['required'], min: 6, max: 52}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const resetPassword = () => {
        document.querySelectorAll('input').forEach(input => input.blur());

        if (handler.checkValid() < 1) {
            setIsExpired(false);
            setIsError(false);
            setIsSuccess(false);

            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_reset_password',
                method: 'POST',
                data: {password: form.password.value},
                params: {token: token},
                success: () => {
                    handler.reset();
                    setIsSuccess(true);
                    handler.setDisabled('password', true);
                    handler.setLoading(false);
                    setLoading(false);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        setIsError(true);
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        handler.setLoading(true);
        setLoading(true);

        routingApiHandlerFetch({
            route: 'api_reset_password_validate',
            method: 'GET',
            params: {token: token},
            success: () => {
                handler.setLoading(false);
                setLoading(false);
            },
            error: (response) => {
                if (response.code === 400) {
                    setIsExpired(true);
                }
                else {
                    setIsError(true);
                }
                handler.setDisabled('password', true);
                handler.setLoading(false);
                setLoading(false);
            }
        });
    }, [token]);

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            overflowX: 'hidden',
            position: 'relative'
        }}>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{
                    width: '100%'
                }}>
                    <Box
                        sx={{
                            padding: '0 25px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{width: '100%', maxWidth: 400}}>

                            <LinkStyled to={'https://www.backlinkshub.io/'}>
                                <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                            </LinkStyled>

                            <Box sx={{margin: '15px 0'}}>
                                <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                    {`Reset your password? 🔒`}
                                </Typography>
                                <Typography sx={{color: color.textPrimary, fontWeight: '100 !important'}}>
                                    Enter your new password below to reset your password.
                                </Typography>
                            </Box>

                            {isError && <>
                                <Alert sx={{opacity: 0.7}} severity="error">Oops ! An unexpected error has occurred.</Alert><br/>
                            </>}
                            {isExpired && <>
                                <Alert sx={{opacity: 0.7}} severity="warning">The password reset link has expired. Please request a new password reset link.
                                </Alert><br/>
                            </>}
                            {isSuccess && <>
                                <Alert sx={{opacity: 0.7}} severity="success">
                                    Your password has been successfully changed. You can now log in with your new password.
                                </Alert><br/>
                            </>}

                            <div>
                                <Box sx={{padding: '5px 0'}}>
                                    <TextFieldComponent handler={handler} id={'password'} onSubmit={resetPassword}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%', marginBottom: '25px'}}>
                                    <ButtonComponent label={'Send reset link'} onClick={resetPassword} loading={loading} disabled={isExpired || isSuccess || isError}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%'}}>
                                    <LinkStyled to={routingHandlerGetRoutePathname('login')}>
                                        <ArrowBackIos sx={{fontSize: 16, color: color.primary, verticalAlign: 'bottom'}}/> Return to login
                                    </LinkStyled>
                                </Box>
                            </div>

                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Box>
    );
}

export default ResetPassword;
