import React from 'react';
import {Link} from "react-router-dom";
import {Box} from "@mui/system";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {Alert, Fade} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "../../Config/Theme";
import {ArrowBackIos} from '@mui/icons-material';

// ** Styled Components
const LinkStyled = styled(Link)(() => ({
    fontSize: '15px',
    textDecoration: 'none',
    color: '#05b1cc'
}));

function ForgotPassword() {
    const [isError, setIsError] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'E-mail',
            helperText: 'Enter your e-mail.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const forgotPassword = (event) => {
        document.querySelectorAll('input').forEach(input => input.blur());

        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setIsError(false);
            setIsSuccess(false);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_forgot_password',
                method: 'POST',
                data: {email: form.email.value},
                success: () => {
                    handler.reset();
                    setIsSuccess(true);
                    handler.setLoading(false);
                    setLoading(false);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        setIsError(true);
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }

    };

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            overflowX: 'hidden',
            position: 'relative'
        }}>
            <Fade in={true} {...{timeout: 500}}>
                <Box sx={{
                    width: '100%'
                }}>
                    <Box
                        sx={{
                            padding: '0 25px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{width: '100%', maxWidth: 400}}>

                            <LinkStyled to={'https://www.backlinkshub.io/'}>
                                <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                            </LinkStyled>

                            <Box sx={{margin: '15px 0'}}>
                                <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                    {`Forgot your password? 🔒`}
                                </Typography>
                                <Typography sx={{color: color.textPrimary, fontWeight: '100 !important'}}>
                                    Enter your email and we will send you instructions to reset your password
                                </Typography>
                            </Box>

                            {isError && <><Alert sx={{opacity: 0.7}} severity="error">Oops ! An unexpected error has occurred.</Alert><br/></>}
                            {isSuccess && <>
                                <Alert sx={{opacity: 0.7}} severity="success">
                                    If your email address exists in our database, you will receive a password recovery link to your email address within a few minutes.
                                    Please check your spam or junk folder if you do not see the email in your inbox.
                                </Alert><br/>
                            </>}

                            <div>
                                <Box sx={{padding: '5px 0'}}>
                                    <TextFieldComponent handler={handler} id={'email'} onSubmit={forgotPassword}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%', marginBottom: '25px'}}>
                                    <ButtonComponent label={'Send reset link'} onClick={forgotPassword} loading={loading}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%'}}>
                                    <LinkStyled to={routingHandlerGetRoutePathname('login')}>
                                        <ArrowBackIos sx={{fontSize: 16, color: color.primary, verticalAlign: 'bottom'}}/> Return to login
                                    </LinkStyled>
                                </Box>
                            </div>

                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Box>
    );
}

export default ForgotPassword;
