import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import MUIDataTable from "mui-datatables";
import {CircularProgress, Tooltip, IconButton, Box, Typography} from "@mui/material";
import {Refresh, List} from '@mui/icons-material';
import {cacheTableData, cacheTableFilter, cacheTablePage, cacheTableServer, cacheTableOrderBy, cacheTableRowsPerPage} from "../Cache";
import ButtonComponent from "./ButtonComponent";
import {Link} from "react-router-dom";
import {color} from "src/Config/Theme";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {tableReduxReload} from "../Redux/tableRedux";

const useStyles = {
    circularProgress: {
        margin: '10px',
        color: color.primary
    },
    title: {
        margin: '10px',
        color: color.textPrimary + ' !important',
        fontSize: '14px',
    },
    span: {
        margin: '0 10px',
        fontSize: '10px',
        height: '10px',
        display: 'inline-block',
        verticalAlign: 'bottom',
        padding: '18px 0',
        color: color.textPrimary
    },
    buttonActionFirst: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important',
        width: '200px',
    },
    buttonActionSecond: {
        margin: '10px -8px 10px 20px !important',
        fontSize: '10px !important'
    },
    buttonActionThird: {
        margin: '10px -8px 10px 10px !important',
        fontSize: '10px !important'
    },
    buttonSelection: {
        margin: '10px 5px',
        marginRight: '14px'
    }
};

function TableComponent(props) {
    const tableRedux = useSelector(state => state.tableRedux);
    const dispatch = useDispatch();

    const [data, setData] = React.useState(cacheTableData[props.id] ?? []);
    const [loading, setLoading] = React.useState(false);
    const [count, setCount] = React.useState(0);

    const loadData = () => {
        if (props.promiseData) {
            setLoading(true);

            props.promiseData((data) => {
                setData([...data]);
                setLoading(false);
                if (!props.noCache) cacheTableData[props.id] = [...data];
            });
        }
        else if (props.promiseServerData) {
            onTable('reload');
        }
    };
    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTable: {
                styleOverrides: {
                    root: {
                        boxShadow: (props.noShadow ? 'none !important' : '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'),
                        border: (props.noShadow ? '1px solid rgba(0,0,0,0.10)' : 0),
                        borderRadius: '5px',
                        overflow: 'hidden !important',
                        height: '100%',
                        margin: (props.margin ? props.margin + 'px' : 0),
                        position: 'relative',
                        paddingBottom: '0',
                        boxSizing: 'border-box',
                        background: color.backgroundPrimary + ' !important',
                        '&  > div:nth-of-type(3)': {
                            minHeight: props.height ? props.height + ' !important' : (props.minHeight ? props.minHeight : ''),
                            // maxHeight: props.height ? props.height + ' !important' : '',
                        }
                    }
                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        '& th': {
                            background: color.tableHead + ' !important',
                        }
                    }
                }
            },
            MuiTableBody: {
                styleOverrides: {
                    root: {
                        '& tr': {
                            background: color.backgroundPrimary + ' !important',
                            '&:hover': {
                                background: color.primaryOpacityMore + ' !important'
                            }
                        }
                    }
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        minHeight: '50px',
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '8px',
                        color: color.textSecondary + ' !important',
                        borderBottom: '1px solid' + color.textSecondary + ' !important',
                    }
                }
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    sortActive: {
                        color: color.textPrimaryActive + ' !important'
                    },
                    sortAction: {
                        '& span': {
                            '& svg': {
                                color: color.textPrimaryActive + ' !important',
                            }
                        }
                    }
                }
            },
            MuiTableFooter: {
                styleOverrides: {
                    root: {
                        '& tr > td': {
                            borderBottom: '0 !important',
                        }
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        color: color.textSecondary + ' !important',
                    },
                    actions: {
                        '& button': {
                            color: color.textSecondary + ' !important',
                            '&:hover': {
                                background: color.primaryOpacityMore + ' !important'
                            }
                        }
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: color.textSecondary + ' !important',
                    },
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            background: color.primaryOpacityMore + ' !important'
                        },
                    },
                }
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        background: color.backgroundPrimary + ' !important',
                        color: color.textPrimary + ' !important',
                        '& .Mui-selected': {
                            background: color.primaryOpacity + ' !important',
                            color: color.textPrimaryActive + ' !important',
                        },
                        '& li:hover': {
                            background: color.primaryOpacityMore
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        background: 'none !important'
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        background: color.backgroundPrimary + ' !important',
                        '& span': {
                            color: color.textPrimary + ' !important',
                        }
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    popper: {
                        '& > div': {
                            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                            background: color.backgroundPrimary + ' !important',
                            color: color.textPrimaryActive + ' !important',
                        }
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '&::before': {
                            borderBottom: '1px solid ' + color.primaryOpacity + ' !important'
                        },
                        '&::after': {
                            borderBottom: '1px solid ' + color.primary + ' !important'
                        },
                        '& input': {
                            color: color.textPrimaryActive + ' !important',
                        }
                    }
                }
            }
        }
    });
    const onTable = (action = '', tableState = {}) => {
        if (props.promiseServerData) {
            if (action === 'tableInitialized') {
                if (!cacheTableServer[props.id]) {
                    cacheTableServer[props.id] = {
                        rowsPerPage: tableState.rowsPerPage,
                        page: tableState.page,
                        searchText: '',
                        sortOrder: tableState.sortOrder ? {
                            name: tableState.sortOrder.name,
                            direction: tableState.sortOrder.direction
                        } : {},
                    };
                }
                else {
                    cacheTableServer[props.id].searchText = '';
                }
            }

            if (
                action === 'reload' ||
                action === 'search' ||
                action === 'sort' ||
                action === 'filterChange' ||
                action === 'changePage' ||
                action === 'changeRowsPerPage'
            ) {
                if (action !== 'reload') {
                    cacheTableServer[props.id]['rowsPerPage'] = tableState.rowsPerPage;
                    cacheTableServer[props.id]['page'] = tableState.page;
                    cacheTableServer[props.id]['searchText'] = tableState.searchText;
                    cacheTableServer[props.id]['sortOrder'] = tableState.sortOrder ? {
                        name: tableState.sortOrder.name,
                        direction: tableState.sortOrder.direction
                    } : {};
                }

                setLoading(true);
                props.promiseServerData((data, count) => {
                    setLoading(false);
                    setData([...data]);
                    setCount(count);
                }, cacheTableServer[props.id]);
            }
        }
    };
    const options = {
        responsive: 'vertical',

        // Action
        download: false,
        search: props.search !== false,
        filter: false,
        print: false,
        customToolbar: () => {
            return (
                <>
                    {!props.noReload && <Tooltip title={"Reload list"}>
                        <IconButton
                            sx={{'&:hover': {background: color.primaryOpacityMore}}}
                            onClick={() => {
                                if (props.onBeforeRefresh) props.onBeforeRefresh();
                                loadData();
                            }}>
                            <Refresh/>
                        </IconButton>
                    </Tooltip>}
                    {props.actionThird && <ButtonComponent color={'#28a745'} sx={useStyles.buttonActionThird} label={props.actionThird.label} onClick={props.actionThird.onClick}/>}
                    {
                        props.actionSecond && <ButtonComponent
                            loading={props.actionSecond.loading}
                            color={props.actionSecond.color ?? '#35A2EB'}
                            sx={useStyles.buttonActionSecond}
                            outlined={props.actionSecond.outlined}
                            label={props.actionSecond.label}
                            onClick={props.actionSecond.onClick}
                            disabled={props.actionSecond.disabled}
                        />
                    }
                    {
                        props.actionFirst && (
                            props.actionFirst.link ?
                                <Link to={props.actionFirst.link}>
                                    <ButtonComponent loading={props.actionFirst.loading} sx={useStyles.buttonActionFirst} label={props.actionFirst.label}/>
                                </Link> :
                                <ButtonComponent loading={props.actionFirst.loading} sx={useStyles.buttonActionFirst} label={props.actionFirst.label} onClick={props.actionFirst.onClick}/>
                        )
                    }
                </>
            );
        },

        // Traduction
        textLabels: {
            body: {
                noMatch: loading ? 'Loading...' : 'No result.',
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort by${column.label}`
            },
            pagination: {
                next: "Next page",
                previous: "Previous page",
                rowsPerPage: "Line per page:",
                displayRows: "of"
            },
            toolbar: {
                search: "Research",
                downloadCsv: "Download CSV",
                print: "To print",
                viewColumns: "Colonnes",
                filterTable: "Filtre"
            },
            viewColumns: {
                title: "Column display",
                titleAria: "Show/Hide column"
            },
            selectedRows: {
                text: "selected line(s)",
                delete: "Delete"
            },
            filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET"
            }
        },

        // Cache
        onFilterChange: (column, list) => {
            cacheTableFilter[props.id] = list;
        },
        onChangePage: (number) => {
            cacheTablePage[props.id] = number;
        },
        onColumnSortChange: (changedColumn, direction) => {
            cacheTableOrderBy[props.id] = {name: changedColumn, direction: direction};
        },
        onChangeRowsPerPage: (numberOfRows) => {
            cacheTableRowsPerPage[props.id] = numberOfRows;
        },
        sortOrder: cacheTableOrderBy[props.id] ?? {name: 'uuid', direction: 'desc'},
        page: cacheTablePage[props.id] ?? 0,
        rowsPerPage: cacheTableRowsPerPage[props.id] ?? 25,
        rowsPerPageOptions: [10, 25, 50, 100],

        // Select
        selectableRowsHeader: !!props.actionSelection,
        selectableRowsHideCheckboxes: false,
        selectableRowsOnClick: false,
        selectableRows: (props.actionSelection) ? 'multiple' : 'none',
        customToolbarSelect: () => {
            return <>
                {props.actionSelection && <ButtonComponent sx={useStyles.buttonSelection} label={props.actionSelection.label} onClick={props.actionSelection.onClick}/>}
            </>;
        },
        onRowsDelete: false,
        isRowSelectable: () => {
            return true;
        },
        rowsSelected: [],

        // Server
        count: count,
        serverSide: !!props.promiseServerData,
        onTableInit: onTable,
        onTableChange: onTable
    };

    React.useEffect(loadData, []);
    React.useEffect(() => {
        if (tableRedux.id === props.id) {
            dispatch(tableReduxReload({id: null}));
            loadData();
        }
    }, [tableRedux]);
    React.useEffect(() => {
        if (props.refreshInterval) {
            const interval = setInterval(() => {
                loadData();
                setLoading(false);
            }, props.refreshInterval);
            return () => clearInterval(interval);
        }
    });

    if (cacheTableFilter[props.id]) {
        for (let index in props.columns) {
            props.columns[index].options.filterList = cacheTableFilter[props.id][index];
        }
    }

    return (
        <div style={{height: '100%'}}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={data}
                    columns={props.columns}
                    options={options}
                    title={props.loading ||
                    (loading && !props.hideLoading) ? <><CircularProgress size={20} sx={useStyles.circularProgress}/> <Box sx={useStyles.span}>Updating data.</Box></> :
                        props.title ? <Box sx={useStyles.title}>
                            <Typography sx={{color: color.textPrimary + ' !important', fontWeight: '600'}}>
                                {
                                    props.icon ? <props.icon sx={{color: color.textPrimary + ' !important', verticalAlign: 'bottom', marginRight: '10px'}}/> :
                                        <List sx={{color: color.textPrimary + ' !important', verticalAlign: 'bottom', marginRight: '10px'}}/>
                                }
                                {props.title}
                            </Typography>
                        </Box> : ''}
                />
            </ThemeProvider>
        </div>
    )
}

export default TableComponent;
